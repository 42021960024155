import AdminLayout from "../../dashboard/components/AdminLayout";
import DashboardContainer from "../../../core/components/DashboardContainer";
import useAsyncRequest from "../../../networking/useAsyncRequest";
import { getAuditPlainByApplicationId } from "../../../store/AuditorFlow/auditorListService";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loader from "../../../core/components/Loader";
import AuditPlan from "../../decisionFlow/AuditPlan";
import { useLocation } from "react-router-dom";

const AuditSchedule = () => {
  // const details = useSelector((state: any) => state.onBoarding.details);
  // const { application } = details;
  const location = useLocation();
  const { data } = location.state;

  const { applicationId } = data;

  const [responseData, setResponseData] = useState<any>([]);
  const { execute, loading } = useAsyncRequest(getAuditPlainByApplicationId);

  const handleResponse = (res: any) => {
    const { status, data } = res;
    if (status === 200) {
      setResponseData(data);
    } else {
      setResponseData([]);
    }
  };

  useEffect(() => {
    const payload = {
      applicationId: applicationId,
    };
    execute(payload, handleResponse);
  }, []);


  if (loading) {
    return <Loader open={loading} />;
  }

  return (
    <AdminLayout>
      <DashboardContainer>
        <AuditPlan data={data} />
      </DashboardContainer>
    </AdminLayout>
  );
};

export default AuditSchedule;
