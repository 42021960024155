import { makeApiRequest } from "../../networking/apiClient";
import { CREATEORDER, MAKEPAYMENT } from "../../networking/endpoints";

export const makepayment = async (payload: any) => {
  try {
    const response = await makeApiRequest(MAKEPAYMENT, "POST", payload, {});
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const createOrder = async (payload: any) => {
  try {
    const response = await makeApiRequest(CREATEORDER, "POST", payload, {});
    return response.data;
  } catch (error) {
    throw error;
  }
};

