import { useSelector } from "react-redux";
import AdminDashboard from "./adminDashboard";
import { USERTYPE } from "../../core/constants";
import UserApplicationProgressDashboard from "./userDashboard";
import AuditorDashboard from "./AuditorDashboard";
import { Box } from "@mui/material";
import DecisionDashboard from "./DecisionDashboard";

const Dashboard = () => {
  const user = useSelector((state: any) => state.auth.user);

  return (
    <Box>
      {user.role === USERTYPE.ADMIN ? (
        <AdminDashboard />
      ) : user.role === USERTYPE.AUDITOR ? (
        <AuditorDashboard />
      ) : user.role === USERTYPE.DECISION ? (
        <DecisionDashboard />
      ) : (
        <UserApplicationProgressDashboard />
      )}
    </Box>
  );
};

export default Dashboard;
