import { apiClient, makeApiRequest } from "../../networking/apiClient";
import {
  CHANGEPASSWORD,
  CREATESURVEILLLACE,
  DELETECOMPLAINTSEMAILS,
  DELETEUSER,
  GETALLCOMPLAINTSEMAILS,
  GETAPPLICATIONDETAILSBYMAIL,
  GETSURVEILLLACE,
  GOOGLELOGIN,
  LOGIN,
  REGISTERCOMPLAINTSEMAILS,
  REGISTRATION,
  RESETPASSWORD,
  RESETPASSWORDBYUSER,
  VERIFYEMAIL,
  VERIFYFORGETPASSWORDEMAIL,
  VERIFYOTP,
} from "../../networking/endpoints";

export const userLogin = async (payload: any) => {
  try {
    const response = await apiClient.post(LOGIN, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const userRegistration = async (payload: any) => {
  try {
    const response = await apiClient.post(REGISTRATION, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const googleLogin = async (payload: any) => {
  try {
    const response = await apiClient.post(GOOGLELOGIN, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const resetPassword = async (payload: any) => {
  try {
    const response = await apiClient.post(RESETPASSWORD, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updatePassword = async (payload: any) => {
  try {
    const response = await apiClient.post(CHANGEPASSWORD, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteUser = async (payload: any) => {
  try {
    const response = await apiClient.post(DELETEUSER, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const verifyEmail = async (payload: any) => {
  try {
    const response = await apiClient.post(VERIFYEMAIL, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getApplicationbyEmail = async (payload: any) => {
  try {
    const response = await apiClient.post(GETAPPLICATIONDETAILSBYMAIL, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const registerComplaintEmails = async (payload: any) => {
  try {
    const response = await makeApiRequest(REGISTERCOMPLAINTSEMAILS, "POST", payload, {});
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAllComplaintsEmails = async (payload: any) => {
  try {
    const response = await makeApiRequest(GETALLCOMPLAINTSEMAILS, "POST", payload, {});
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteComplaintsEmails = async (payload: any) => {
  try {
    const response = await makeApiRequest(DELETECOMPLAINTSEMAILS, "POST", payload, {});
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const verifyforgetPasswordEmail = async (payload: any) => {
  try {
    const response = await makeApiRequest(VERIFYFORGETPASSWORDEMAIL, "POST", payload, {});
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const verifyOtp = async (payload: any) => {
  try {
    const response = await makeApiRequest(VERIFYOTP, "POST", payload, {});
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const resetPasswordByUser = async (payload: any) => {
  try {
    const response = await apiClient.post(RESETPASSWORDBYUSER, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createSurveillance = async (payload: any) => {
  try {
    const response = await apiClient.post(CREATESURVEILLLACE, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSurveillanceByUser = async (payload: any) => {
  try {
    const response = await apiClient.post(GETSURVEILLLACE, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
}


