import { Box, Grid } from "@mui/material";
import TextWithLabel from "../../../core/components/TextWithLabel";

const CompanyInfo = (data: any) => {
  console.log(data, "asdasghfd");

  const detailField = (title: string, subtitle: any) => {
    const isBool = typeof subtitle === "boolean";
    let subTitle = "";
    if (isBool) {
      subTitle = subtitle === true ? "Yes" : "No";
    } else {
      subTitle = subtitle ? subtitle : "-";
    }
    return (
      <Grid item xs={2} sm={4} md={4}>
        {title && <TextWithLabel title={title} subTitle={subTitle} />}
      </Grid>
    );
  };

  return (
    <Box pl={1}>
      <Grid
        container
        spacing={4}
        alignItems={"baseline"}
        columns={{ xs: 2, sm: 12, md: 16 }}
        sx={{
          paddingTop: 2,
          paddingBottom: 2,
        }}
      >
        {detailField("Name", data.data.companyDetail.nameOfCompany)}
        {detailField("Audit Date", data.data.auditDate)}
        {detailField("Scope of Certification", data.data.certification)}
        {detailField("Application Scheme", data.data.category)}
        {detailField("Category Code", data.data.subCategory)}
      </Grid>
    </Box>
  );
};

export default CompanyInfo;
