import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FC, FocusEventHandler, useState } from "react";
import TextInputLight from "../../../../core/components/Input/TextInputLight";

interface DetailSiteProps {
  onChange: any;
  value?: any;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  error?: any;
  touched?: any;
  setFieldValue?: any;
  certification? : string
}

const SiteDetailComponent: FC<DetailSiteProps> = ({
  value,
  onChange,
  onBlur,
  error,
  touched,
  setFieldValue,
  certification
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const style = { borderBottom: "none" };
  const mainRow = { borderBottom: "none", width: "15%" };
  const subRow = { borderBottom: "none", width: "30%" };
  const width = "100%";

  return (
    <Box>
      {isMobile ? (
        <Box mt={4}>
          <TextInputLight
            width={"100%"}
            label="Total Number of Sites/ Location"
            onChange={onChange}
            value={value.totalNumberOfSites}
            name="totalNumberOfSites"
            onBlur={onBlur}
            textFieldHeigt={50}
            error={
              error["total"] && touched["total"] && error["total"]
                ? true
                : false
            }
            helperText={error["total"] && touched["total"] && error["total"]}
          />
          {value?.totalNumberOfSites > 1 && (
            <Box>
              <Typography>Number of Site/Location</Typography>
              <TextInputLight
                label="Permanent"
                width={"100%"}
                onChange={onChange}
                value={value["noOfSiteLocationPermanent"]}
                name="noOfSiteLocationPermanent"
                onBlur={onBlur}
                textFieldHeigt={50}
                error={
                  error["total"] && touched["total"] && error["total"]
                    ? true
                    : false
                }
                helperText={
                  error["total"] && touched["total"] && error["total"]
                }
              />
            
              <TextInputLight
                label="Temporary"
                width={"100%"}
                onChange={onChange}
                value={value["noOfSiteLocationTemporary"]}
                name="noOfSiteLocationTemporary"
                onBlur={onBlur}
                textFieldHeigt={50}
                error={
                  error["total"] && touched["total"] && error["total"]
                    ? true
                    : false
                }
                helperText={
                  error["total"] && touched["total"] && error["total"]
                }
              />
              <>
              {(certification == 'scopeExtension') &&
               <>
                <Typography>Additional for scope extension</Typography>
              <TextInputLight
                label="Permanent"
                width={"100%"}
                onChange={onChange}
                value={value["scopePermanent"]}
                name="scopePermanent"
                onBlur={onBlur}
                textFieldHeigt={50}
                error={
                  error["total"] && touched["total"] && error["total"]
                    ? true
                    : false
                }
                helperText={
                  error["total"] && touched["total"] && error["total"]
                }
              />
              <TextInputLight
                label="Temporary"
                width={"100%"}
                onChange={onChange}
                value={value["scopeTemporary"]}
                name="scopeTemporary"
                onBlur={onBlur}
                textFieldHeigt={50}
                error={
                  error["total"] && touched["total"] && error["total"]
                    ? true
                    : false
                }
                helperText={
                  error["total"] && touched["total"] && error["total"]
                }
              />
              </>
            }
              </>
            </Box>
          )}
        </Box>
      ) : (
        <TableContainer sx={{ width: isMobile ? "100%" : "98%", ml: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={style}></TableCell>
                {value?.totalNumberOfSites > 1 && (
                  <TableCell
                    style={{
                      borderBottom: "none",
                    }}
                  >
                    <Typography>Number of Site/Location</Typography>
                  </TableCell>
                )}
                {(value?.totalNumberOfSites > 1 && certification == 'scopeExtension' )&& (
                  <TableCell style={style}>
                    <Typography>Additional for scope extension</Typography>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell style={mainRow}>
                  <TextInputLight
                    width={300}
                    label="Total Number of Sites/ Location"
                    onChange={onChange}
                    value={value.totalNumberOfSites}
                    name="totalNumberOfSites"
                    onBlur={onBlur}
                    textFieldHeigt={25}
                    error={
                      error["total"] && touched["total"] && error["total"]
                        ? true
                        : false
                    }
                    helperText={
                      error["total"] && touched["total"] && error["total"]
                    }
                  />
                </TableCell>
                {value?.totalNumberOfSites > 1 && (
                  <TableCell style={subRow}>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <TextInputLight
                        label="Permanent"
                        width={width}
                        onChange={onChange}
                        value={value["noOfSiteLocationPermanent"]}
                        name="noOfSiteLocationPermanent"
                        onBlur={onBlur}
                        textFieldHeigt={25}
                        error={
                          error["total"] && touched["total"] && error["total"]
                            ? true
                            : false
                        }
                        helperText={
                          error["total"] && touched["total"] && error["total"]
                        }
                      />
                      <TextInputLight
                        label="Temporary"
                        width={width}
                        onChange={onChange}
                        value={value["noOfSiteLocationTemporary"]}
                        name="noOfSiteLocationTemporary"
                        onBlur={onBlur}
                        textFieldHeigt={25}
                        error={
                          error["total"] && touched["total"] && error["total"]
                            ? true
                            : false
                        }
                        helperText={
                          error["total"] && touched["total"] && error["total"]
                        }
                      />
                    </Box>
                  </TableCell>
                )}
                {(value?.totalNumberOfSites > 1 && certification == 'scopeExtension')&& (
                  <TableCell style={subRow}>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <TextInputLight
                        label="Permanent"
                        width={width}
                        onChange={onChange}
                        value={value["scopePermanent"]}
                        name="scopePermanent"
                        onBlur={onBlur}
                        textFieldHeigt={25}
                        error={
                          error["total"] && touched["total"] && error["total"]
                            ? true
                            : false
                        }
                        helperText={
                          error["total"] && touched["total"] && error["total"]
                        }
                      />
                      <TextInputLight
                        label="Temporary"
                        width={width}
                        onChange={onChange}
                        value={value["scopeTemporary"]}
                        name="scopeTemporary"
                        onBlur={onBlur}
                        textFieldHeigt={25}
                        error={
                          error["total"] && touched["total"] && error["total"]
                            ? true
                            : false
                        }
                        helperText={
                          error["total"] && touched["total"] && error["total"]
                        }
                      />
                    </Box>
                  </TableCell>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default SiteDetailComponent;
