import * as Yup from "yup";

export const signInValidationSchema = Yup.object().shape({
  email: Yup.string().email().required("Email-ID is required"),
  password: Yup.string().required("Password is required"),
});

export const signUpValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().email().required("Email-ID is required"),
  password: Yup.string().required("Password is required"),
});

export const forgetPasswordSchema = Yup.object().shape({
  email: Yup.string().email().required("Email-ID is required"),
});

export const compayDetailsSchema = Yup.object().shape({
  nameOfCompany: Yup.string().required("Company Name is required"),
  telephone: Yup.string().required("Telephone No is required"),
  email: Yup.string().email().required("Email-ID is required"),
  address: Yup.string().required("Address is required"),
  pinCode: Yup.string().required("Pin Code is required"),
  invoicePinCode: Yup.string().required("Pin Code is required"),
  invoiceAddress: Yup.string().required("Invoice Address is required"),
  totalNumberOfSites: Yup.number()
    .typeError("Please enter number value only")
    .moreThan(-1, "Negative values not accepted"),
  noOfWorkShifts: Yup.number()
    .typeError("Please enter number value only")
    .moreThan(-1, "Negative values not accepted"),
  noOfEmployeePerShift: Yup.number()
    .typeError("Please enter number value only")
    .moreThan(-1, "Negative values not accepted"),
  mainSite: Yup.number()
    .typeError("Please enter number value only")
    .moreThan(-1, "Negative values not accepted"),
  siteTwo: Yup.number()
    .typeError("Please enter number value only")
    .moreThan(-1, "Negative values not accepted"),
  siteThree: Yup.number()
    .typeError("Please enter number value only")
    .moreThan(-1, "Negative values not accepted"),
  siteFour: Yup.number()
    .typeError("Please enter number value only")
    .moreThan(-1, "Negative values not accepted"),
  siteFive: Yup.number()
    .typeError("Please enter number value only")
    .moreThan(-1, "Negative values not accepted"),
  siteSix: Yup.number()
    .typeError("Please enter number value only")
    .moreThan(-1, "Negative values not accepted"),
});

export const certificateSchema = Yup.object().shape({
  radio: Yup.string().required("Certificate is required"),
  category: Yup.string().required("Category is required"),
  subCategory: Yup.string().required("Subcategory is required"),
});

export const quotationShcema = Yup.object().shape({
  applicationFee: Yup.number().required("Please Fill"),
  accreditationFee: Yup.number().required("Please Fill"),
  stageOneAuditFee: Yup.number().required("Please Fill"),
  stageTwoAuditFee: Yup.number().required("Please Fill"),
  documentReviewForRenuewalAudit: Yup.number().required("Please Fill"),
  renewalAuditFree: Yup.number().required("Please Fill"),
  surverillianceAuditOneFree: Yup.number().required("Please Fill"),
  surverillianceAuditTwoFree: Yup.number().required("Please Fill"),
});

export const commonValidationSchema = Yup.object().shape({});

export const adminReviewShcema = Yup.object().shape({});

export const stageOneAuditCheckListA = Yup.object().shape({});

export const findingsSchema = Yup.object().shape({
  scheduleOfAssessment : Yup.string().required("Schedule of Assessment is Required"),
  dateOfAssessment : Yup.string().required("Date of Assessment is Required"),
  assessmentTeam : Yup.string().required("Assessment Team is Required"),
});

export const addCertificateSchema = Yup.object().shape({});

export const addEmailSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  emailId: Yup.string().email().required("Email-ID is required"),
});

export const adminUserSchema = Yup.object().shape({});

export const addAuditorSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().email().required("Email-ID is required"),
  designation: Yup.string(),
  role: Yup.string().required("role is required"),
});

export const passwordResetSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Password must be at least 8 characters long")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Please confirm your password"),
});

export const passwordChangeSchema = Yup.object().shape({
  password: Yup.string().required("Password is required"),
  newPassword: Yup.string()
    .min(8, "Password must be at least 8 characters long")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword")], "Passwords must match")
    .required("Please confirm your password"),
});

export const issueCertificateSchema = Yup.object().shape({
  effectiveDate : Yup.string().required("Effective date is required"),
  expiryDate : Yup.string().required("Expiry date is required"),
  referenceNo :Yup.string().required("Reference is required")
});
