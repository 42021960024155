import { Box, Typography } from "@mui/material";

const NoData = ({
  message,
  imageUrl,
  imageAlt = "No Data",
  sx = {},
}: {
  message: string;
  imageUrl: any;
  imageAlt?: string;
  sx?: object;
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      height="100%"
      sx={{ ...sx }}
    >
      <Box
        component="img"
        src={imageUrl}
        alt={imageAlt}
        sx={{
          width: 400,
          height: 400,
          objectFit: "contain",
        }}
      />
      <Typography variant="h6" color="textSecondary">
        {message}
      </Typography>
    </Box>
  );
};

export default NoData;
