import { Alert, Box, Button, Grid, Modal, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FontWeight } from "../../../core/types";
import TextWithLabel from "../../../core/components/TextWithLabel";
import LabelDownloadComponent from "../../../core/components/LabelDownloadComponent";
import { AUDITDRAFT, ISSUECERTIFICATE } from "../../../router/config";
import { useSelector } from "react-redux";
import { USERTYPE } from "../../../core/constants";
import { useEffect, useState } from "react";
import TextInputLight from "../../../core/components/Input/TextInputLight";
import useAsyncRequest from "../../../networking/useAsyncRequest";
import {
  decisonAccept,
  decisonReject,
} from "../../../store/Application/applicationService";
import Loader from "../../../core/components/Loader";
import DashboardContainer from "../../../core/components/DashboardContainer";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: '10px'
};

const Overview = ({ data, trigger }: any) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const navigate = useNavigate();
  const user = useSelector((state: any) => state.auth.user);

  const [alertOpen, setAlertOpen] = useState(false);
  const [rejectOpen, setRejectOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [rejectReason, setRejectReason] = useState("");
  const handleOpenAlert = () => setAlertOpen(true);
  const handleCloseAlert = () => setAlertOpen(false);

  const handleOpenRejectAlert = () => {
    setRejectReason("");
    setRejectOpen(true);
  };
  const handleCloseRejectAlert = () => setRejectOpen(false);

  const { role } = user;
  const { companyDetail } = data;

  const { applicationId } = data;

  const { execute, loading } = useAsyncRequest(decisonAccept);

  const { execute: rejectApplication, loading: rejectLoading } =
    useAsyncRequest(decisonReject);

  const handleResponse = (res: any) => {
    const { status } = res;
    if (status == 200) {
      setSuccess(true);
      setMessage("Accepeted SuccessFully");
      trigger();
      handleCloseAlert();
    } else {
      setSuccess(false);
      setMessage("Something went wrong");
      handleCloseAlert();
    }
  };

  const rejectHandleResponse = (res: any) => {
    const { status } = res;
    if (status == 200) {
      setSuccess(true);
      setMessage("Rejected SuccessFully");
      handleCloseRejectAlert();
      trigger();
    } else {
      setSuccess(false);
      setMessage("Something went wrong");
      handleCloseRejectAlert();
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setMessage("");
    }, 2000);
    return () => clearTimeout(timer);
  }, [message, success]);

  const detailField = (title: string, subtitle: any) => {
    const isBool = typeof subtitle === "boolean";
    let subTitle = "";
    if (isBool) {
      subTitle = subtitle === true ? "Yes" : "No";
    } else {
      subTitle = subtitle ? subtitle : "-";
    }
    return (
      <Grid item xs={2} sm={4} md={4}>
        {title && <TextWithLabel title={title} subTitle={subTitle} />}
      </Grid>
    );
  };

  const confirmAlert = () => {
    return (
      <Modal
        open={alertOpen}
        onClose={handleCloseAlert}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Please confirm, are you sure want to accept application
          </Typography>
          <Box
            display={"flex"}
            alignItems={"flex-end"}
            justifyContent={"flex-end"}
            gap={1}
          >
            <Button onClick={() => handleCloseAlert()} variant="outlined">No</Button>
            <Button
              onClick={() => {
                const payload = {
                  applicationId: applicationId,
                };
                execute(payload, handleResponse);
              }}
              variant="contained"
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  };

  const rejectAlert = () => {
    return (
      <Modal
        open={rejectOpen}
        onClose={handleCloseRejectAlert}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Please confirm, are you sure want to reject application
          </Typography>
          <TextInputLight
            textFieldHeigt={50}
            label="Reason"
            value={rejectReason}
            onChange={(val: any) => {
              setRejectReason(val.target.value);
            }}
          />
          <Box
            display={"flex"}
            alignItems={"flex-end"}
            justifyContent={"flex-end"}
            gap={1}
          >
            <Button onClick={() => handleCloseRejectAlert()} variant="outlined">No</Button>
            <Button
              disabled={!rejectReason}
              onClick={() => {
                const payload = {
                  applicationId: applicationId,
                  reason: rejectReason,
                };
                rejectApplication(payload, rejectHandleResponse);
              }}
              variant="contained"
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  };

  return (
    <DashboardContainer>
    <Box>
      <Loader open={loading || rejectLoading} />
      {confirmAlert()}
      {rejectAlert()}
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        flexDirection={ isMobile ? 'column' : 'row'}
      >
        <Typography fontWeight={FontWeight.MEDIUM} variant="h5">
          {companyDetail.nameOfCompany}
        </Typography>
        <Box sx={{ padding:isMobile ? 1 : 2, background: "green", borderRadius: 5 }} mb={2}>
          <Typography color={"white"}>{data.activeStatus}</Typography>
        </Box>
      </Box>
      <Box display={"flex"}>
        <Box pr={10}>{detailField("Application ID", data?.applicationNo)}</Box>
      </Box>

        <Grid
          container
          spacing={4}
          alignItems={"baseline"}
          columns={{ xs: 2, sm: 12, md: 16 }}
          sx={{
            paddingTop: 2,
            paddingBottom: 2,
          }}
        >
          {detailField("Status", data.activeStatus)}
          {detailField("Audit Date", data.auditDate)}
          {detailField("Quotation", "Rs " + data.quoteTotal)}
          {detailField("Lead Auditor", data.leadAuditor)}
          {detailField("Lead Auditor", data.leadAuditor)}
          {detailField("Auditor", data.auditor)}
        </Grid>
        <Box>
        </Box>


        {(data.isEligible && role === USERTYPE.DECISION && data.type !== "SURVEILLANCE" ) && (
          <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <Button
              onClick={() => {
                handleOpenAlert();
              }}
              variant="outlined"
              sx={{ mr: 2, color: "green", borderColor: "green" }}
            >
              Accept
            </Button>
            <Button
              onClick={() => {
                handleOpenRejectAlert();
              }}
              variant="outlined"
              sx={{ mr: 2, color: "red", borderColor: "red" }}
            >
              Reject
            </Button>
          </Box>
        )}

        {data?.activeStatus === "Application Accepted" && (
            <Typography>Application is Accepted by Desision Commitee</Typography>
          )}

        {data?.activeStatus === "Application Rejected" && (
          <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <Typography>Application Reject by Desision Commitee </Typography>
            <Typography>Reason : {data?.rejectReason}</Typography>
          </Box>
        )}

        {/* ADMIN DRAFT BUTTON */}
        {((data?.activeStatus === "Application Accepted" ||
          data?.activeStatus === "Draft Created" ||
          data?.activeStatus === "Draft Accepted" ||
          data?.activeStatus === "Draft Updated" ||
          data?.activeStatus === "Draft Rejected" ||
          data?.activeStatus === "Certificate Issued" ||
          data?.activeStatus === "Decision Accepted"
        ) &&
          role === USERTYPE.ADMIN) && ( 
            <LabelDownloadComponent
              label={
                data.activeStatus === "Draft Created" ||
                data.activeStatus === "Certificate Issued"
                  ? "Draft Created"
                  : data.activeStatus === "Draft Rejected"
                  ? "Draft Reject by User, Update Draft"
                  : data.activeStatus === "Draft Updated"
                  ? "Draft Updated"
                  : data.activeStatus === "Draft Accepted"
                  ? "Draft Accepted"
                  : "Create Draft"
              }
              onClick={() => {
                navigate(AUDITDRAFT, {
                  state: { data: data },
                });
              }}
            />
          )}

        {(data.activeStatus === "Draft Created" ||
          data.activeStatus === "Draft Accepted" ||
          data.activeStatus === "Certificate Issued") &&
          role !== USERTYPE.ADMIN && (
            <LabelDownloadComponent
              label={
                data.activeStatus === "Certificate Issued" ||
                data.activeStatus === "Draft Accepted"
                  ? "Draft Created"
                  : "Create Draft"
              }
              onClick={() => {
                navigate(AUDITDRAFT, {
                  state: { data: data },
                });
              }}
            />
          )}


      {(data.activeStatus === "Certificate Issued" || data.activeStatus === "Draft Accepted" && role === USERTYPE.ADMIN) && (
          <LabelDownloadComponent
          bgColor={"#b7dfb9"}
            label={
              data.activeStatus === "Certificate Issued"
                ? "Certificate Issued"
                : "Issue Certificate"
            }
            onClick={() => {
              navigate(ISSUECERTIFICATE, {
                state: { data: data },
              });
            }}
          />
        )}        
      {message && (
        <Alert severity={success ? "success" : "error"}>{message}</Alert>
      )}
    </Box>
    </DashboardContainer>
  );
};

export default Overview;
