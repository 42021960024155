import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { FontWeight } from "../../types";
import { USERTYPE } from "../../constants";

interface DropdownMenuProps {
  label?: string;
  menuLabel?: string;
  handleChange?: (event: React.ChangeEvent<{ value: unknown }>) => void;
  value?: string;
  name?: string;
  option?: any[];
  width?: string | number;
  error?: any 
  touched?: any;
  type?: string; // Define specific types if possible (e.g., USERTYPE.AUDITOR)
}

const DropdownMenu : React.FC<DropdownMenuProps> = ({
  label = "Select Option",
  menuLabel = "Menu",
  handleChange = () => {},
  value = "",
  name = "",
  option = [],
  width = "100%",
  error = false,
  touched = false,
  type = USERTYPE.AUDITOR,
}: any) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box sx={{ width: width }}>
      <Typography
        fontWeight={FontWeight.BOLD}
        fontSize={16}
        color={"#929396"}
        mb={1}
      >
        {label}
      </Typography>
      <FormControl
        variant="outlined"
        fullWidth
        error={error[name] && touched[name] && error[name] ? true : false}
      >
        <InputLabel id="demo-simple-select-outlined-label">
          {menuLabel}
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={value}
          onChange={handleChange}
          label="Select Option"
          name={name}
          IconComponent={ArrowDropDownIcon}
          fullWidth
          style={{ minWidth: "100%", width: "100%" }}
          MenuProps={{
            PaperProps: {
              style: {
                minWidth: isMobile ? "90%" : "25%",
              },
            },
          }}
        >
          {option &&
            option.map((item: any) => {
              return type === USERTYPE.AUDITOR ? (
                <MenuItem value={item.firstName + " " + item.lastName}>
                  {item.firstName + " " + item.lastName}
                </MenuItem>
              ) : (
                <MenuItem value={item}>{item}</MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default DropdownMenu;
