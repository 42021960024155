import AdminLayout from "../../dashboard/components/AdminLayout";
import useAsyncRequest from "../../../networking/useAsyncRequest";
import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  InputAdornment,
  Pagination,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import Loader from "../../../core/components/Loader";
import { getAllComplaints } from "../../../store/Application/applicationService";
import { FontWeight } from "../../../core/types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import { noData } from "../../../core/Images";
import NoData from "../../../core/components/NoData";


const AllComplaintsList = () => {
  const [complaintsList, setComplaints] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;
  const { execute, loading } = useAsyncRequest(getAllComplaints);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleResponse = (res: any) => {
    const { status, data } = res;
    if (status === 200) {
      setComplaints(data?.complaints);
    }
  };

  useEffect(() => {
    execute({}, handleResponse);
  }, []);

  const filteredComplaints = complaintsList.filter((item: any) =>
    `${item.firstName} ${item.lastName}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  // Calculate the number of pages needed
  const pageCount = Math.ceil(filteredComplaints.length / itemsPerPage);

  // Get complaints for the current page
  const paginatedComplaints = filteredComplaints.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  // Handle page change
  const handlePageChange = (event: any, value: any) => {
    setPage(value);
  };

  return (
    <AdminLayout>
      <Loader open={loading} />
      <Box
        display="flex"
        justifyContent="center"
        minHeight="100vh"
        mt={3}
      >
        <Box
          display="flex"
          flexDirection="column"
          width={isMobile ? "100%" : "60%"}
        >
          {/* Heading and Search Bar */}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            flexDirection={isMobile ? "column" : "row"}
            gap={2}
            mb={4}
          >
            <Typography fontSize={20} fontWeight="bold" color="#483F3D">
              Complaints
            </Typography>
            <TextField
              label="Search by Company"
              variant="outlined"
              size="small"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{ width: isMobile ? "100%" : "auto" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon htmlColor="#9C6CA6"/>
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          {/* Complaints List */}
          <Box mt={2} width="100%">
            {paginatedComplaints.length > 0 ? (
              paginatedComplaints.map((item: any) => (
                <Accordion key={item._id}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel-${item._id}-content`}
                    id={`panel-${item._id}-header`}
                  >
                    <Box display="flex" justifyContent="space-between" width="100%" color="#9C6CA6">
                      <Typography fontWeight={isMobile ? FontWeight.REGULAR : FontWeight.MEDIUM}>
                        {item.firstName} {item.lastName}
                      </Typography>
                      <Typography fontWeight={isMobile ? FontWeight.REGULAR : FontWeight.MEDIUM}>
                       {item._id}
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body1" color="#D26A32">Email: {item.userEmail}</Typography>
                    <Typography variant="body1" color="#D26A32">Subject: {item.subject}</Typography>
                    <Typography fontWeight={FontWeight.MEDIUM} color="#D26A32">Message: {item.body}</Typography>
                  </AccordionDetails>
                </Accordion>
              ))
            ) : (
              <NoData 
                imageUrl={noData}
                message="No complaints found."
              />
            )}
          </Box>

          {/* Pagination */}
          {pageCount > 1 && (
            <Box display="flex" justifyContent="center" mt={3}>
              <Pagination
                count={pageCount}
                page={page}
                onChange={handlePageChange}
                color="primary"
              />
            </Box>
          )}
        </Box>
      </Box>
    </AdminLayout>
  );
};

export default AllComplaintsList;
