import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import OnBoardingStepper from "../../../../core/components/Stepper/OnBoardingStepper";
import { onBoardingStepperList } from "../../../../core/constants";
import OnBoardingHeader from "../../Components/onBoardingHeader";
import TermsConditionsCheck from "../../../../core/components/TermsConditionCheck";
import { useNavigate } from "react-router-dom";
import { DASHBOARD, REVIEW } from "../../../../router/config";
import { useState } from "react";
import { FontWeight } from "../../../../core/types";
import useAsyncRequest from "../../../../networking/useAsyncRequest";
import { updateAgreement } from "../../../../store/Oboarding/TnCServices";
import { useSelector } from "react-redux";
import Loader from "../../../../core/components/Loader";
import TnC from "../../../../core/Images/backgrounds/tnc.png";
import {
  certificationAgressment,
  conformityTerms,
  declarationTerms,
} from "./constant";

const TermsAndConditions = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [conformityCheck, setConformityCheck] = useState<boolean>(false);
  const [certificationCheck, setCertificationCheck] = useState<boolean>(false);
  const [delclarationCheck, setDelclarationCheck] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const applicationDetails = useSelector(
    (state: any) => state.onBoarding.details
  );

  const { application, companyDetail } = applicationDetails || {};

  const { applicationId, certification } = application || {};

  const { nameOfCompany, address } = companyDetail || {};

  const {
    execute,
    loading,
    error: err,
    response,
  } = useAsyncRequest(updateAgreement);

  const handleResponse = (res: any) => {
    const { status } = res;
    if (status === 200) {
      navigate(REVIEW);
    }
  };

  return (
    <Box>
      <OnBoardingHeader />
      <Box
        sx={{
          paddingLeft: { xs: 2, md: 5 },
          paddingRight: { xs: 2, md: 5 },
          paddingTop: { xs: 2, md: 3 },
        }}
      >
        <OnBoardingStepper list={onBoardingStepperList} active={4} />
      </Box>
      <Loader open={loading} />

      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        pt={isMobile ? 2 : 10}
      >
        <img
          src={TnC}
          style={{
            paddingTop: isMobile ? 2 : 10,
            paddingBottom: 40,
            height: isMobile ? 200 : 350,
          }}
        />
        <Box>
          <TermsConditionsCheck
            linkText="Agreement of the use of license, Certificate and Halal Mark of Conformity"
            onChange={(value: any) => setConformityCheck(value)}
            termsText={conformityTerms(nameOfCompany)}
          />

          <TermsConditionsCheck
            linkText="Agreement of Certification"
            onChange={(value: any) => setCertificationCheck(value)}
            termsText={certificationAgressment(
              nameOfCompany,
              address,
              certification
            )}
          />

          <TermsConditionsCheck
            linkText="Declaration of Impartiality, Conflict of Interest & Integrity"
            onChange={(value: any) => setDelclarationCheck(value)}
            termsText={declarationTerms(nameOfCompany)}
          />
        </Box>
        {error && (
          <Typography fontWeight={FontWeight.BOLD} color={"red"} mt={5}>
            Please agree to the Terms and Conditions
          </Typography>
        )}
        <Button
          sx={{ mt: 5, mb : 3 }}
          variant="contained"
          onClick={() => {
            setError(false);
            if (conformityCheck && certificationCheck && delclarationCheck) {
              const payload = {
                applicationId: applicationId,
              };
              execute(payload, handleResponse);
              setError(false);
            } else {
              setError(true);
            }
          }}
        >
          Proceed
        </Button>
      </Box>
    </Box>
  );
};

export default TermsAndConditions;
