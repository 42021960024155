import { BASE_URL } from "../../networking/apiClient";
import { format, parse, isDate, isValid } from "date-fns";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export const getEmailByName = (fullName: string, data: any): string => {
  const [firstName, lastName] = fullName.split(" ");
  for (let user of data) {
    if (user.firstName === firstName && user.lastName === lastName) {
      return user.email;
    }
  }
  return "User not found";
};

export const findLabelByName = (name: any, list: any) => {
  const audit = list.find((item: any) => item.name === name);
  return audit ? audit.label : null; // Return null if not found
};

export const downloadFile = async (
  fileUrl: string,
  fileName: string
): Promise<void> => {
  try {
    const URL = BASE_URL + fileUrl;
    window.open(URL, "_blank");
    // const link = document.createElement("a");
    //link.href = BASE_URL + fileUrl;
    // link.download = fileName;
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
  } catch (error) {
    console.error("There was a problem with the download:", error);
  }
};

export const dateFormat = (dateStr: any) => {
  const date = new Date(dateStr);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZoneName: "short",
  };
  return new Intl.DateTimeFormat("en-US", options).format(date);
};

export const formatDate = (dateStr: string | null): string => {
  if (!dateStr) {
    return "Invalid date";
  }

  const possibleFormats = [
    "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
    "EEE MMM dd yyyy HH:mm:ss 'GMT'xxx (zzzz)",
    "MM/dd/yyyy",
    "yyyy-MM-dd",
    "yyyy/MM/dd",
    "dd-MM-yyyy",
    "dd/MM/yyyy",
    "MMM dd, yyyy",
    "MMMM dd, yyyy",
    "yyyy.MM.dd",
    "yyyy MM dd",
    "yyyyMMdd",
  ];

  let date: Date | null = null;

  for (const formatString of possibleFormats) {
    try {
      date = parse(dateStr, formatString, new Date());
      if (isDate(date) && isValid(date)) {
        break;
      }
    } catch (e) {
      // Ignore and try the next format
    }
  }

  if (!date || !isValid(date)) {
    return "Invalid date";
  }
  const formattedDate = format(date, "MM/dd/yyyy, HH:mm:ss xxx");
  return formattedDate;
};

export const getColors = (theme: any, color: any) => {
  switch (color) {
    case "secondary":
      return theme.palette.secondary;
    case "error":
      return theme.palette.error;
    case "warning":
      return theme.palette.warning;
    case "info":
      return theme.palette.info;
    case "success":
      return theme.palette.success;
    default:
      return theme.palette.primary;
  }
};

export const roundToDecimalPlace = (num: any, decimalPlaces: any) => {
  const factor = Math.pow(10, decimalPlaces);
  return Math.round(num * factor) / factor;
};

export function createData(
  applicationId: string,
  name: string,
  applicationDate: any,
  type: any,
  status: any
) {
  return { name, applicationId, applicationDate, type, status };
}

export const capitalize = (string: any) => {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const toCamelCase = (str: any) => {
  return str
    .split(" ")
    .map((word: any, index: any) => {
      if (index === 0) {
        return word.toLowerCase();
      } else {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }
    })
    .join("");
};

export const handleDownloadPdf = async (id: any) => {
  const input = document.getElementById(id);

  if (input) {
    try {
      const canvas = await html2canvas(input, {
        scrollY: -window.scrollY,
      });

      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const padding = 10;
      const imgWidth = 210 - 2 * padding;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = padding;

      while (heightLeft > 0) {
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= 297 - 2 * padding;
        position -= 297 - 2 * padding;
        if (heightLeft > 0) {
          pdf.addPage();
        }
      }
      pdf.save("download.pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  }
};

export const formatDatePickerDate = (date? : any)=>{
  if(date){
  const dateObject = new Date(date);
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(dateObject.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
}

export const getCurrentDateFormatted = (): string => {
  const date = new Date();
  const day = String(date.getDate()).padStart(2, '0'); // Get day and pad with 0 if needed
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month (0-based index) and pad with 0
  const year = date.getFullYear(); // Get full year
  return `${year}-${month}-${day}`; // Format as DD/MM/YYYY
};

export const getDateMinusMonths = (months: number): string => {
  const date = new Date();
  date.setMonth(date.getMonth() - months); // Subtract the specified number of months
  const day = String(date.getDate()).padStart(2, '0'); // Get day and pad with 0 if needed
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month (0-based index) and pad with 0
  const year = date.getFullYear(); // Get full year
  return `${year}-${month}-${day}`; // Format as DD/MM/YYYY
};

export default async function displayRazorpay(data : any,onSuccess :any) {
  const options = {
    key: 'rzp_test_cMuyDFwu113sPk',
    key_secret: 'vn7Ay6oXAP5loXEZf58OYzkt',
    currency: 'INR',
    amount: data.amount * 100,
    order_id : data.id,
    name: "HALAL Point",
    description: "Halal Certification",
    handler: function (response : any) {
      onSuccess(response)
    },
    prefill: {
      name: "Halal Point pvt ltd",
      email: "halalpointh@gmail.com",
      contact: "9999999999",
    },
  };

  const paymentObject = new (window as any).Razorpay(options);
  paymentObject.open();
}
