import * as React from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import { TransitionProps } from "@mui/material/transitions";
import CompanyDetailComponent from "../../../core/components/CompanyDetailComponent";
import { Box } from "@mui/material";
import { FontWeight } from "../../../core/types";
import { certificationAgressment, conformityTerms, declarationTerms } from "../../onBoarding/Pages/TermAndConditions/constant";
import ScrollDialog from "../../../core/components/TermsDialog";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CheckListViewComponent = ({ data, details }: any) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <IconButton onClick={handleClickOpen}>
        <VisibilityRoundedIcon color="primary" />
      </IconButton>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {data?.label}
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box p={5}>
          {data.name == "applicationForm" ? (
            <CompanyDetailComponent data={details} />
          ) : data.name == "certificateAgreement" ? (
            <ScrollDialog
              open={true}
              handleClose={handleClose}
              title={"Certificate of Agreement"}
              dialogContent={certificationAgressment(
                details.companyDetail.nameOfCompany,
                details.companyDetail.address,
                details.certification
              )}
            />
          ) : data.name == "declarationOFConformity" ? 
            <ScrollDialog
              open={true}
              handleClose={handleClose}
              title={"Declaration of Conformity"}
              dialogContent={conformityTerms(details.companyDetail.nameOfCompany)}
            /> : data.name == "declarationOFundertaking" ? 
            <ScrollDialog
              open={true}
              handleClose={handleClose}
              title={"Declaration of Undertaking"}
              dialogContent={declarationTerms(details.companyDetail.nameOfCompany)}
            /> :  (
            <Box
              pt={10}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              fontSize={24}
              fontWeight={FontWeight.BOLD}
            >
              No Data Found
            </Box>
          )}
        </Box>
      </Dialog>
    </React.Fragment>
  );
};

export default CheckListViewComponent;
