import { Box, useMediaQuery, useTheme } from "@mui/material";

const DashboardContainer = ({ children }: any) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));


  return (
    <Box
      sx={{
        flex: 1,
        flexGrow: 1,
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
        borderRadius: 5,
        padding: isMobile ? 2 :  3,
        mt: 2,
      }}
    >
      {children}
    </Box>
  );
};
export default DashboardContainer;
