import { Box, Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import OnBoardingHeader from "../../Components/onBoardingHeader";
import OnBoardingStepper from "../../../../core/components/Stepper/OnBoardingStepper";
import { onBoardingStepperList } from "../../../../core/constants";
import { useNavigate } from "react-router-dom";
import { DASHBOARD, TERMSCONDITIONS } from "../../../../router/config";
import SuccessIcon from "../../../../Icons/SuccessIcon";
import { FontWeight } from "../../../../core/types";
import { useSelector } from "react-redux";
import useAsyncRequest from "../../../../networking/useAsyncRequest";
import { createOrder, makepayment } from "../../../../store/Oboarding/reviewServices";
import Loader from "../../../../core/components/Loader";
import ReviewImage from "../../../../core/Images/backgrounds/reviewImage.svg";
import { useEffect } from "react";
import displayRazorpay from "../../../../core/utils";

const Review = () => {
  const navigate = useNavigate();
  const applicationDetails = useSelector(
    (state: any) => state.onBoarding.details
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const loadScript = (src : string) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
     document.body.appendChild(script);
   });
};

useEffect(() => {
    loadScript("https://checkout.razorpay.com/v1/checkout.js");
});


  const { application } = applicationDetails || {};

  const { applicationId, activeStatus, quoteTotal, userEmail } = application || {};

  const { execute, loading } = useAsyncRequest(makepayment);

  const { execute : createPayment, loading : createLoading } = useAsyncRequest(createOrder);

  const handleResponse = (res: any) => {
    const { status } = res;
    if (status === 200) {
      navigate(DASHBOARD);
    }
  };

  const onSuccuss = (res : any) =>{
    const {status, data} = res
    if(status === 200){
      displayRazorpay(data, onPaymentSuccuss)
    }
  }

  const onPaymentSuccuss = (res : any) =>{
      const payload = {
      applicationId: applicationId,
      orderId : res.razorpay_order_id,
      paymentId : res.razorpay_payment_id,
      signature : res.razorpay_signature
    };

    execute(payload, handleResponse);
  }

  return (
    <Box>
      <OnBoardingHeader />
      <Box
        sx={{
          paddingLeft: { xs: 2, md: 5 },
          paddingRight: { xs: 2, md: 5 },
          paddingTop: { xs: 2, md: 3 },
        }}
      >
        <OnBoardingStepper list={onBoardingStepperList} active={5} />
      </Box>
      <Loader open={loading || createLoading} />

      {activeStatus === "Quotation Created" ||
      activeStatus === "Quotation Accepted" ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems={"center"}
          flexDirection={"column"}
        >
          <img src={ReviewImage} style={{ paddingTop: 50, width : isMobile ? "80%" : 400 }} />
          <Typography
            variant="h6"
            fontWeight={FontWeight.BOLD}
            p={2}
            textAlign={"center"}
          >
            Your Application is in Review, a confirmation mail will be send to
            your registered E-mail ID
          </Typography>
          <Typography
            variant="body1"
            fontWeight={FontWeight.BOLD}
            pt={2}
            pb={4}
          >
            The process will take up to 2-3 business days
          </Typography>
        </Box>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems={"center"}
          flexDirection={"column"}
          sx={{ mt: { xs: 0, md: 4 } }}
          pt={isMobile ? 5 : 20}
        >
          <SuccessIcon />
          <Typography variant="h6" textAlign={'center'} fontWeight={FontWeight.BOLD} pt={2}>
            Your Application is successfully Reviewed{" "}
          </Typography>
          <Typography
            variant="body1"
            fontWeight={FontWeight.BOLD}
            pt={2}
            pb={4}
          >
            Please complete the payment for future procesing{" "}
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              const payload = {
                applicationId: applicationId,
                userEmail : userEmail,
                amount : quoteTotal,
              };
              createPayment(payload, onSuccuss)
              //
             
            }}
          >
            Proceed to Payment
          </Button>
        </Box>
      )}

      <Box
        display="flex"
        justifyContent="center"
        sx={{ mt: { xs: 2, md: 4 } }}
      ></Box>
    </Box>
  );
};

export default Review;
