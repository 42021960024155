import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { FontWeight } from "../../../../core/types";
import { AUDITPLAN, DECISIONAPPLICATIONVIEW, VIEWAUDITFINDINGS } from "../../../../router/config";
import { useNavigate } from "react-router-dom";
import LabelDownloadComponent from "../../../../core/components/LabelDownloadComponent";

const SurveilanceCard = ({ applicationData }: any) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const {applicationId, activeStatus} = applicationData

  return (
    <Box>
    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
      <Box mt={2}>
        <Typography mb={1} fontWeight={FontWeight.MEDIUM}>
          Surveilance ID: {applicationData?.applicationNo}
        </Typography>
        <Typography mb={1} fontWeight={FontWeight.MEDIUM}>
          Type: {applicationData?.certification.toUpperCase()}
        </Typography>
        <Typography mb={1} fontWeight={FontWeight.MEDIUM}>
          Status:{" "}
          {applicationData?.activeStatus == "Surveillance"
            ? "Active"
            : applicationData?.activeStatus}
        </Typography>
        <Button
          variant="outlined"
          sx={{ mt: 2 }}
          onClick={() => {
            navigate(DECISIONAPPLICATIONVIEW, {
              state: { data: applicationData },
            });
          }}
        >
          Surveillance overview
        </Button>
      </Box>
      <Box>
      <Box>
          {applicationData.leadAuditor ? (
            <Box mr={4} sx={{marginTop : !isMobile ? 0  : 4}}>
              <Typography fontWeight={FontWeight.BOLD}>
                Audit is planned
              </Typography>
              {applicationData.leadAuditor !== null ? (
                <Box
                  alignSelf={"flex-start"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"flex-start"}
                >
                  <Box>
                    {applicationData.auditDate && (
                      <Typography mt={1} fontSize={isMobile ? '0.875rem': '1rem'} fontWeight={FontWeight.MEDIUM}>
                        Audit Date: {applicationData.auditDate}
                      </Typography>
                    )}
                    {applicationData.leadAuditor && (
                      <Typography fontSize={isMobile ? '0.875rem': '1rem'} fontWeight={FontWeight.MEDIUM} mt={1}>
                        Auditor: {applicationData.leadAuditor}
                      </Typography>
                    )}
                  </Box>
                  <Box pl={4}>
                    {applicationData?.auditPlanStatus && (
                      <Box
                        p={1}
                        border={1}
                        borderRadius={7}
                        borderColor={
                          applicationData?.auditPlanStatus === "rejected"
                            ? "#f04235"
                            : "green"
                        }
                      >
                        <Typography
                          color={
                            applicationData?.auditPlanStatus === "rejected"
                              ? "#f04235"
                              : "green"
                          }
                        >
                          {applicationData?.auditPlanStatus === "rejected"
                            ? "Rejected"
                            : applicationData?.auditPlanStatus === "updated"
                            ? "Updated"
                            : "Accepeted"}
                        </Typography>
                      </Box>
                    )}
                    {applicationData?.activeStatus === "Audit Planned" && (
                      <Button
                        sx={{fontSize : isMobile?  10 : 14}}
                        onClick={() => {
                          navigate(AUDITPLAN, {
                            state: { data: applicationData },
                          });
                        }}
                        variant="outlined"
                      >
                        Action required
                      </Button>
                    )}
                  </Box>
                </Box>
              ) : null}
              <Button
                variant="outlined"
                sx={{ mt: 3, width: "100%" }}
                onClick={() => {
                  navigate(AUDITPLAN,{
                    state: { data: applicationData },
                  });
                }}
              >
                Audit Plan
              </Button>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
    <Grid
        container
        spacing={ isMobile ? 1 : 4}
        alignItems={"baseline"}
        columns={{ xs: 2, sm: 12, md: 16 }}
        sx={{
          paddingTop: 2,
        }}
      >
        {activeStatus === 'Audit Completed' && 
        <Grid item xs={4} sm={4} md={4}>
          <LabelDownloadComponent
           bgColor='#abd5e9'
            label={"Audit Findings"}
            onClick={() =>
              navigate(VIEWAUDITFINDINGS, {
                state: { data: { applicationId } },
              })
            }
          />
           </Grid> }
      </Grid>
    </Box>
  );
};

export default SurveilanceCard;
