export const onBoardingStepperList = [
  "Certification",
  "Company Details",
  "Quotation",
  "Terms & Conditions",
  "Review"
];

export enum TYPE {
  ONBOARD = "ONBOARD",
}

export enum DATEFORMAT {
  DATETIMEFORMATE = "MM/DD/YYYY",
}

export enum USERTYPE {
  ADMIN = "ADMIN",
  USER = "USER",
  AUDITOR = "AUDITOR",
  DECISION = "DECISION",
  SUPER_ADMIN = "SUPER_ADMIN",
}

export enum APPLICATIONTYPE {
  FRESH = "FRESH",
  RENEWAL = "RENEWAL",
  COMPLETED = "COMPLETED",
  AUDITCOMPLETED = "ADUITCOMPLETED",
}

export const categoryList = [
  "Food Scheme",
  "Animal Slaughtering"
]

export const foodSchemeSubCategory = [
  "AI. Farming of Animals for Meat/ Milk/ Eggs/ Honey",
  "AII. Farming of Fish and Seafood",
  "BI. Farming of Plants (other than grains and pulses)",
  "BII. Farming of Grains and Pulses",
  "CI. Processing of perishable animal products",
  "CII. Processing of perishable plant products",
  "CIII. Processing of perishable animal and plant products (mixed products)",
  "CIV. Processing of ambient stable products",
  "DI. Production of Feed",
  "DII. Production of Pet Food",
  "E. Catering",
  "FI. Retail / Wholesale",
  "FII. Food Broking / Trading",
  "GI. Provision of Transport and Storage Service for Perishable Food and Feed",
  "GII. Provision of Transport and Storage Services for Ambient Stable Food and Feed",
  "H. Services",
  "I. Production of Food Packaging and Packaging Material",
  "J. Equipment manufacturing",
  "K. Production of (Bio) Chemicals"
]


export const animalSubCategory = [
  "CV. Animal slaughtering"
]

