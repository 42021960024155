import { Box, Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import AdminLayout from "../../dashboard/components/AdminLayout";
import DashboardContainer from "../../../core/components/DashboardContainer";
import { FontWeight } from "../../../core/types";
import { useLocation, useNavigate } from "react-router-dom";
import useAsyncRequest from "../../../networking/useAsyncRequest";
import { getrequiredList } from "../../../store/AuditorFlow/auditorListService";
import Loader from "../../../core/components/Loader";
import { useEffect, useState } from "react";
import {
  ASSESSMENTFINDINGS,
  AUDITCHECKLIST,
  AUDITCHECKLISTVIEW,
  OPENCLOSEMEETINGINSTRUSTIONS,
  REQUIREDCHECKLIST,
  REQUIREDLIST,
} from "../../../router/config";

const RequiredList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data } = location.state;

  const [responseData, setResponseDate] = useState<any>({});
  const [checklist, setCheckList] = useState<any>([]);
  const [fmshecklist, setfmsCheckList] = useState<any>([]);

  const { applicationId } = data;

  const { execute, loading } = useAsyncRequest(getrequiredList);

  const handleResponse = (res: any) => {
    const { status, data } = res;

    if (status == 200) {
      const { requiredListId } = data;
      const { checkList, fmsChecklist } = requiredListId;
      setCheckList(checkList);
      setfmsCheckList(fmsChecklist);
      setResponseDate(requiredListId);
    }
  };

  useEffect(() => {
    const payload = {
      applicationId: applicationId,
    };
    execute(payload, handleResponse);
  }, [navigate]);

  const disableButton = (checkList: any, fmshecklist: any) => {
    const fsms = fmshecklist ? fmshecklist : [];
    let completedData = [];

    checkList?.map((item: any) => {
      if (item?.status == "Completed") {
        completedData.push(item);
      }
    });
    if (fmshecklist !== null) {
      fmshecklist?.map((item: any) => {
        if (item?.status == "Completed") {
          completedData.push(item);
        }
      });
    }

    return completedData.length == checkList.length + fsms.length
      ? false
      : true;
  };

  const LabelList = (checkList: any, onClick: any, onClickCompleted: any) => {
    return (
      <Box>
        {checkList !== null &&
          checkList?.map(
            (item: any, index: any) =>
              item && (
                <Box
                  sx={{cursor : "pointer"}}
                  display={"flex"}
                  alignContent={"center"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  pt={1}
                  pb={1}
                  onClick={() =>
                    item?.status === "Completed"
                      ? onClickCompleted({ item: item, label: item?.label })
                      : onClick({ item: item, id: index })
                  }
                >
                  <Typography
                    fontSize={18}
                    p={1}
                    fontWeight={FontWeight.MEDIUM}
                    key={`${index}`}
                  >
                    {item.label}
                  </Typography>
                  <Box
                    sx={{
                      backgroundColor:
                        item.status === "Completed" ? "#54A93F" : "#03a9f4",
                      height: 35,
                      width:  100,
                      minWidth : 110,
                      padding: 1,
                      borderRadius: 1,
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <Typography
                      alignSelf={"center"}
                      alignContent={"center"}
                      pl={2}
                      pr={2}
                      color={"white"}
                    >
                      {item.status ? item.status : "To Do"}
                    </Typography>
                  </Box>
                </Box>
              )
          )}
      </Box>
    );
  };

  return (
    <AdminLayout>
      <DashboardContainer>
        <Loader open={loading} />
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
        <Typography variant="h6" fontWeight={FontWeight.BOLD} pl={2} pt={3}>
          Please select Required on-site checklist
        </Typography>
        <Button onClick={()=>{
          navigate(REQUIREDCHECKLIST, {
            state: { data: data },
          });
        }}>Edit</Button>
        </Box>
        {checklist && (
          <Box pl={1} pt={2}>
            {LabelList(
              checklist,
              (val: any) => {
                navigate(AUDITCHECKLIST, {
                  state: {
                    data: data,
                    item: val.item.label,
                    id: responseData.id,
                    listId: val.id,
                  },
                });
              },
              (val: any) => {
                navigate(AUDITCHECKLISTVIEW, {
                  state: {
                    data: data,
                    item: val.item,
                    label: val.label,
                  },
                });
              }
            )}
          </Box>
        )}
        {fmshecklist !== null && (
          <Box>
            <Typography variant="h6" fontWeight={FontWeight.BOLD} pl={2} pt={3}>
              Halal Audit FSMS Checklist
            </Typography>
            <Box pl={1} pt={2}>
              {LabelList(
                fmshecklist,
                (val: any) => {
                  navigate(AUDITCHECKLIST, {
                    state: {
                      data: data,
                      item: val.item.label,
                      id: responseData.id,
                      listId: val.id,
                    },
                  });
                },
                (val: any) => {
                  navigate(AUDITCHECKLISTVIEW, {
                    state: {
                      data: data,
                      item: val.item,
                      label: val.label,
                    },
                  });
                }
              )}
            </Box>
          </Box>
        )}
        <Box
          pt={7}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          flexDirection={"column"}
          width={"85%"}
        >
          {!disableButton(checklist, fmshecklist) && (
            <Button
              onClick={() => {
                navigate(OPENCLOSEMEETINGINSTRUSTIONS, {
                  state: { data: data },
                });
              }}
            >
              Opening Closing Meeting Instructions
            </Button>
          )}
          <Button
            sx={{ mt: 2 }}
            variant="contained"
            disabled={disableButton(checklist, fmshecklist)}
            color="primary"
            onClick={() => {
              navigate(ASSESSMENTFINDINGS, {
                state: { data: data },
              });
            }}
          >
            Proceed
          </Button>
        </Box>
      </DashboardContainer>
    </AdminLayout>
  );
};

export default RequiredList;
