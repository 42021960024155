import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import FileUpload from "./fileComponent";
import { FontWeight } from "../../../../core/types";
import { useState } from "react";
import TextInputLight from "../../../../core/components/Input/TextInputLight";

const CheckLabelAttachment = ({ list, heading, onChange }: any) => {
  const [files, setFiles] = useState<any>([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleFileUpload = ({ index, file, item }: any) => {
    const updatedFiles = [...files, { file: file, item }];
    setFiles(updatedFiles);
    onChange(updatedFiles);
  };
  return (
    <Box mt={2} display={"flex"} alignItems={"center"} flexDirection={"column"}>
      {heading && (
        <Typography
          variant="h6"
          fontWeight={FontWeight.MEDIUM}
          sx={{ pl: isMobile ? 0 : 5, pt: 2 }}
        >
          {heading}
        </Typography>
      )}
      {list.map((item: any, index: number) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            p={2}
            width={isMobile ? "100%" : "70%"}
          >
            {item.name == "other" ? (
              <TextInputLight
                label="Other"
                width={500}
                onChange={onChange}
                //value={value["noOfSiteLocationTemporary"]}
                name="other"
                textFieldHeigt={50}
              />
            ) : (
              <Box width={isMobile ? "100%" : 500}>
                <Typography>{item.label}</Typography>
              </Box>
            )}
            <FileUpload
              index={index}
              onFileUpload={handleFileUpload}
              item={item.label}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default CheckLabelAttachment;
