import {
  ALLCERTIFICATIONS,
  APPLICATIONLIST,
  AUDITCOMPLETED,
  AUDITORAPPLICATIONINPROGRESS,
  AUDITORDASHBOARD,
  AUDITORLIST,
  AUDITORNOTIFICATION,
  AUDITPLAN,
  CHECKLIST,
  COMUNNICATIONS,
  DASHBOARD,
  DECISIONDASHBOARD,
  DRAFTCREATEDAPPLICATION,
  PROFILE,
  UPCOMMINGAUDITS,
  USERDASHBOARD,
  PAYMENTS,
  VIEWCERT,
  RENEWALAPPLICATIONS,
  ADMINLIST,
  DECISIONLIST,
  USERLIST,
  USERCERTIFICATES,
  FEEDBACKS,
  COMPLAINTS,
  ALLCOMPLAINTSLIST,
  COMPLAINTSEMAILLIST,
} from "../../../router/config";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import ScheduleIcon from "@mui/icons-material/Schedule";
import DonutSmallIcon from "@mui/icons-material/DonutSmall";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ListIcon from "@mui/icons-material/List";
import ChecklistIcon from "@mui/icons-material/Checklist";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import SegmentIcon from "@mui/icons-material/Segment";
import CardMembershipRoundedIcon from "@mui/icons-material/CardMembershipRounded";
import PaidRoundedIcon from "@mui/icons-material/PaidRounded";
import SupportAgentRoundedIcon from "@mui/icons-material/SupportAgentRounded";
import AssessmentRoundedIcon from "@mui/icons-material/AssessmentRounded";
import CircleNotificationsRoundedIcon from "@mui/icons-material/CircleNotificationsRounded";
import ManageHistoryRoundedIcon from "@mui/icons-material/ManageHistoryRounded";
import ChecklistRtlRoundedIcon from "@mui/icons-material/ChecklistRtlRounded";
import DoneAllRoundedIcon from "@mui/icons-material/DoneAllRounded";
import VerifiedUserRoundedIcon from "@mui/icons-material/VerifiedUserRounded";
import DrawRoundedIcon from "@mui/icons-material/DrawRounded";
import PeopleOutlineRoundedIcon from "@mui/icons-material/PeopleOutlineRounded";
import NewspaperRoundedIcon from "@mui/icons-material/NewspaperRounded";
import LocalLibraryRoundedIcon from "@mui/icons-material/LocalLibraryRounded";
import FeedbackRoundedIcon from "@mui/icons-material/FeedbackRounded";
import SentimentDissatisfiedRoundedIcon from "@mui/icons-material/SentimentDissatisfiedRounded";
import AppsRoundedIcon from "@mui/icons-material/AppsRounded";
import QuizRoundedIcon from "@mui/icons-material/QuizRounded";
import RailwayAlertRoundedIcon from "@mui/icons-material/RailwayAlertRounded";
import DynamicFeedRoundedIcon from "@mui/icons-material/DynamicFeedRounded";
import CallReceivedRoundedIcon from "@mui/icons-material/CallReceivedRounded";
import TouchAppRoundedIcon from "@mui/icons-material/TouchAppRounded";
import DoneOutlineRoundedIcon from "@mui/icons-material/DoneOutlineRounded";
import ScatterPlotRoundedIcon from "@mui/icons-material/ScatterPlotRounded";
import ApprovalRoundedIcon from "@mui/icons-material/ApprovalRounded";

interface DrawerItem {
  label: string;
  nav: string;
  icon?: JSX.Element;
  children?: {
    segment: string;
    title: string;
    icon: JSX.Element;
    nav: string;
  }[];
}

export const adminDrawerList: DrawerItem[] = [
  {
    label: "Dashboard",
    nav: DASHBOARD,
    icon: <DashboardIcon />,
    children: [
      {
        segment: "reports",
        title: "Reports",
        icon: <AssessmentRoundedIcon />,
        nav: DASHBOARD,
      },
      {
        segment: "notifications",
        title: "Notifications",
        icon: <CircleNotificationsRoundedIcon />,
        nav: AUDITORNOTIFICATION,
      },
    ],
  },
  {
    label: "Applications",
    nav: APPLICATIONLIST,
    icon: <ListIcon />,
    children: [
      {
        segment: "allApplications",
        title: "All Applications",
        icon: <AssessmentRoundedIcon />,
        nav: APPLICATIONLIST,
      },
      {
        segment: "renewal",
        title: "Renewal",
        icon: <ManageHistoryRoundedIcon />,
        nav: RENEWALAPPLICATIONS,
      },
      {
        segment: "completed",
        title: "Completed",
        icon: <ChecklistRtlRoundedIcon />,
        nav: AUDITCOMPLETED,
      },
      {
        segment: "completed",
        title: "Audit Completed",
        icon: <DoneAllRoundedIcon />,
        nav: AUDITCOMPLETED,
      },
    ],
  },
  {
    label: "Audits",
    nav: "",
    icon: <VerifiedUserRoundedIcon />,
  },
  {
    label: "Drafts",
    nav: DRAFTCREATEDAPPLICATION,
    icon: <DrawRoundedIcon />,
  },
  {
    label: "Users",
    nav: AUDITORLIST,
    icon: <PeopleOutlineRoundedIcon />,
    children: [
      {
        segment: "admin",
        title: "Admin",
        icon: <PeopleOutlineRoundedIcon />,
        nav: ADMINLIST,
      },
      {
        segment: "client",
        title: "Client",
        icon: <PeopleOutlineRoundedIcon />,
        nav: USERLIST,
      },
      {
        segment: "decision",
        title: "Decision",
        icon: <PeopleOutlineRoundedIcon />,
        nav: DECISIONLIST,
      },
      {
        segment: "auditors",
        title: "Auditors",
        icon: <PeopleOutlineRoundedIcon />,
        nav: AUDITORLIST,
      },
    ],
  },
  {
    label: "Customer Support",
    nav: "",
    icon: <LocalLibraryRoundedIcon />,
    children: [
      {
        segment: "feedback",
        title: "Feedback",
        icon: <FeedbackRoundedIcon />,
        nav: FEEDBACKS,
      },
      {
        segment: "complaints",
        title: "Complaints",
        icon: <SentimentDissatisfiedRoundedIcon />,
        nav: ALLCOMPLAINTSLIST,
      },
    ],
  },
  {
    label: "Settings",
    nav: "",
    icon: <SegmentIcon />,
    children: [
      {
        segment: "certificates",
        title: "Certificates",
        icon: <CardMembershipRoundedIcon />,
        nav: ALLCERTIFICATIONS,
      },
      {
        segment: "checklist",
        title: "Checklist",
        icon: <NewspaperRoundedIcon />,
        nav: CHECKLIST,
      },
      {
        segment: "complaintsEmailList",
        title: "Complaints E-mails",
        icon: <NewspaperRoundedIcon />,
        nav: COMPLAINTSEMAILLIST,
      },
    ],
  },
  {
    label: "Profile",
    nav: PROFILE,
    icon: <ManageAccountsIcon />,
  },
];

export const auditorDrawerList: DrawerItem[] = [
  {
    label: "Dashboard",
    nav: AUDITORDASHBOARD,
    icon: <DashboardIcon />,
    children: [
      {
        segment: "overview",
        title: "Overview",
        icon: <AssessmentRoundedIcon />,
        nav: DASHBOARD,
      },
      {
        segment: "notifications",
        title: "Notifications",
        icon: <CircleNotificationsRoundedIcon />,
        nav: AUDITORNOTIFICATION,
      },
    ],
  },
  {
    label: "Audits",
    nav: AUDITORAPPLICATIONINPROGRESS,
    icon: <DonutSmallIcon />,
    children: [
      {
        segment: "recevied",
        title: "Recevied",
        icon: <CallReceivedRoundedIcon />,
        nav: APPLICATIONLIST,
      },
      {
        segment: "ongoing",
        title: "Ongoing",
        icon: <TouchAppRoundedIcon />,
        nav: AUDITORAPPLICATIONINPROGRESS,
      },
      {
        segment: "completed",
        title: "Completed",
        icon: <DoneOutlineRoundedIcon />,
        nav: AUDITCOMPLETED,
      },
    ],
  },
  {
    label: "NC Status",
    nav: AUDITCOMPLETED,
    icon: <ScatterPlotRoundedIcon />,
    children: [
      {
        segment: "ncRecevied",
        title: "NC Recevied",
        icon: <AssessmentRoundedIcon />,
        nav: AUDITCOMPLETED,
      },
      {
        segment: "reviewed",
        title: "Reviewed",
        icon: <ScheduleIcon />,
        nav: AUDITCOMPLETED,
      },
      {
        segment: "approved",
        title: "Approved",
        icon: <ApprovalRoundedIcon />,
        nav: DRAFTCREATEDAPPLICATION,
      },
    ],
  },

  {
    label: "Calender",
    nav: "",
    icon: <CircleNotificationsIcon />,
  },
  {
    label: "Profile",
    nav: PROFILE,
    icon: <ManageAccountsIcon />,
  },
];

export const decisionDrawerList: DrawerItem[] = [
  {
    label: "Dashboard",
    nav: DECISIONDASHBOARD,
    icon: <DashboardIcon />,
    children: [
      {
        segment: "reports",
        title: "Reports",
        icon: <AssessmentRoundedIcon />,
        nav: DECISIONDASHBOARD,
      },
      {
        segment: "notifications",
        title: "Notifications",
        icon: <CircleNotificationsRoundedIcon />,
        nav: AUDITORNOTIFICATION,
      },
    ],
  },
  {
    label: "Applications",
    nav: APPLICATIONLIST,
    icon: <AppsRoundedIcon />,
    children: [
      {
        segment: "recevied",
        title: "Recevied",
        icon: <CallReceivedRoundedIcon />,
        nav: APPLICATIONLIST,
      },
      {
        segment: "underreview",
        title: "Under Review",
        icon: <TouchAppRoundedIcon />,
        nav: APPLICATIONLIST,
      },
      {
        segment: "completed",
        title: "Completed",
        icon: <DoneOutlineRoundedIcon />,
        nav: AUDITCOMPLETED,
      },
    ],
  },
  {
    label: "Profile",
    nav: PROFILE,
    icon: <ManageAccountsIcon />,
  },
];

export const userDrawerList: DrawerItem[] = [
  {
    label: "Overview",
    nav: USERDASHBOARD,
    icon: <DashboardIcon />,
    children: [
      {
        segment: "overview",
        title: "Overview",
        icon: <AssessmentRoundedIcon />,
        nav: DASHBOARD,
      },
      {
        segment: "notifications",
        title: "Notifications",
        icon: <CircleNotificationsRoundedIcon />,
        nav: AUDITORNOTIFICATION,
      },
    ],
  },
  {
    label: "Application",
    nav: APPLICATIONLIST,
    icon: <AppsRoundedIcon />,
  },
  {
    label: "Certificates",
    nav: USERCERTIFICATES,
    icon: <CardMembershipRoundedIcon />,
  },
  {
    label: "Payments",
    nav: PAYMENTS,
    icon: <PaidRoundedIcon />,
  },

  {
    label: "Customer Support",
    nav: "",
    icon: <SupportAgentRoundedIcon />,
    children: [
      {
        segment: "chat",
        title: "Chat",
        icon: <ChatBubbleOutlineIcon />,
        nav: COMUNNICATIONS,
      },
      {
        segment: "faq",
        title: "FAQ",
        icon: <QuizRoundedIcon />,
        nav: COMUNNICATIONS,
      },
      {
        segment: "complaints",
        title: "Complaints",
        icon: <RailwayAlertRoundedIcon />,
        nav: COMPLAINTS,
      },
      {
        segment: "feedback",
        title: "Feedback",
        icon: <DynamicFeedRoundedIcon />,
        nav: COMUNNICATIONS,
      },
    ],
  },
  {
    label: "Profile",
    nav: PROFILE,
    icon: <ManageAccountsIcon />,
  },
];

export const auditFindingsInitialValue = {
  reasonForNonAcceptance: "",
};

export const auditorFindingsInitialValue = {
  recommendationOrStatus: "",
};
